import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircleNotch, faComments, faEnvelopeOpen, faPhone, faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { faThumbsDown as faThumbsDownSolid, faThumbsUp as faThumbsUpSolid } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useCallback, useEffect, useState } from 'react';
import api, { request } from '../../api';
import { ApiPersona } from '../../api/persona';
import { ApiQuestion } from '../../api/question';
import Loader from '../../RbKit/elements/Loader';
import styles from './styles.module.scss';
import parsers from '../../modules/ContentEditor/Parsers';
import { BlockData, Form } from '../../RbKit';
import { ApiAlert } from '../../api/alert';
import { useDebounceSearch, useHandleKeyUp } from '../../RbKit/lib/hooks';
import Textarea from '../../RbKit/elements/Textarea';

export const updateHeight = () => {
    const height: number = window.document.getElementById('frame-base')?.scrollHeight as number;
    setTimeout(() => window.top.postMessage(`${Math.max(height, 0)}px`, '*'), 100);
}

const WebsiteView: FC = (): JSX.Element => {
    const [ welcome, setWelcome ] = useState<string>('Goedemiddag');
    const [ showPopup, setShowPopup ] = useState<string>('');
    const [ chatStatus, setChatStatus ] = useState<string>('online');
    const [ alerts, setAlerts ] = useState<ApiAlert[]>();
    const [ isLoading, setIsloading ] = useState<boolean>(true);
    const [ activePersona, setActivePersona ] = useState<ApiPersona>();
    const [ personas, setPersonas ] = useState<ApiPersona[]>();
    const [ activeQuestion, setActiveQuestion ] = useState<ApiQuestion>();
    const [ stickies, setStickies ] = useState<ApiQuestion[]>();
    const [ searchQuery, setSearchQuery ] = useState<string>('');
    const [ searchResults, setSearchResults ] = useState<ApiQuestion[]>();
    const [ listResults, setListResults ] = useState<ApiQuestion[]>();
    const [ isSearchLoading, setIsSearchLoading ] = useState<boolean>(false);
    const [ feedback, setFeedback ] = useState<number>(0);
    const [ feedbackSent, setFeedbackSent ] = useState<boolean>(false);
    const [ feedbackRes, setFeedbackRes ] = useState<string>('');

    useEffect(() => {
        const handleLink = ({ detail }: any): void => {
            setShowPopup(detail.href);
        }

        const hours = new Date().getHours();
        if (hours < 12) {
            setWelcome('Goedemorgen');
        } else if (hours < 18) {
            setWelcome('Goedemiddag');
        } else {
            setWelcome('Goedenavond');
        }

        window.addEventListener('trigger-popup', handleLink, true);
        return () => window.removeEventListener('trigger-popup', handleLink, true);
    }, []);

    const debounceSearch = useDebounceSearch((query: string) => {
        setIsSearchLoading(true);
        let q = query.split('|');
        api.listQuestions({ limit: 10, query: q[0], personaId: q[1] }, undefined, true).then(({ data }) => {
            setSearchResults(data.data);
            setIsSearchLoading(false);
        });
    });

    const sendFeedback = useCallback((): void => {
        if (!activeQuestion) return;
        api.sendFeedback(activeQuestion.id, feedback, feedbackRes).then(() => {
            setFeedbackSent(true);
            updateHeight();
        });
    }, [feedback, activeQuestion, feedbackRes]);

    useEffect(() => {
        if (feedback === 1) {
            sendFeedback();
        }
        updateHeight();
    }, [feedback]);

    const handleSearch = useCallback((value: string) => {
        setSearchQuery(value);
        if (value === '' || value.length < 3) {
            setSearchResults([]);
        } else {
            debounceSearch(`${value}|${activePersona?.id}`);
        }
    }, [debounceSearch, activePersona]);

    useEffect(() => {
        setSearchResults([]);
        if (activeQuestion) {
            request.post(`front/questions/${activeQuestion.id}/hit`);
        }
    }, [activeQuestion]);

    useEffect(() => {
        setFeedbackSent(false);
        setFeedback(0);
        setFeedbackRes('');
        updateHeight();
    }, [personas, stickies, activePersona, activeQuestion]);

    useEffect(() => {
        api.listAlerts({ limit: 999 }, undefined, true).then(({ data }) => {
            setAlerts(data.data);
        });

        api.listPersonas({ limit: 999 }, undefined, true).then(({ data }) => {
            setPersonas(data.data);
            setIsloading(false);
        });

        // setTimeout(() => {
        //     (window as any).$zopim.livechat.window.hide();

        //     (window as any).$zopim(function() {
        //         (window as any).$zopim.livechat.setOnStatus((status: string) => {
        //             setChatStatus(status);
        //         });
        //     });
        // }, 500);
    }, []);

    const updateStickies = (): void => {
        setIsloading(true);
        api.listQuestions({
            limit: 6,
            personaId: activePersona?.id,
            sticky: true,
        }, undefined, true).then(({ data }) => {
            setIsloading(false);
            setStickies(data.data);
        });
    }

    useEffect(() => {
        updateStickies();

        if (activePersona === undefined) {
            setActiveQuestion(undefined);
            setSearchResults([]);
            setSearchQuery('');
        }
    }, [activePersona]);

    const handlePersona = (p: ApiPersona): void => {
        setActivePersona(p.id === activePersona?.id ? undefined : p);

        (window as any).dataLayer.push({
            event: 'faqModuleCategory',
            events: {
                category: 'FAQ module',
                action: 'Category',
                label: p.name
            }
        });
    }

    const search = (): void => {
        if (searchQuery === '') return;
        setActiveQuestion(undefined);
        setSearchResults([]);
        setIsSearchLoading(true);
        api.listQuestions({ limit: 20, query: searchQuery, trackQuery: true, personaId: activePersona?.id }, undefined, true).then(({ data }) => {
            setListResults(data.data);
            setIsSearchLoading(false);
        });
    }

    useHandleKeyUp(() => {
        search();
    }, [13]);

    return (<>
        {showPopup && (
            <article className={`content notice notice--noticeExternalLinks fullscreen notice--light ${showPopup !== '' && 'enabled'}`} data-name="noticeExternalLinks">
                <div className="wrapper">
                    <div className="notice__content">
                        <p>Deze link brengt u naar een website buiten het Roche-domein. Roche is derhalve niet verantwoordelijk voor de inhoud van deze website.</p>
                    </div>
                        <div className="notice__disable">
                            <input id="notice__disable-popup" type="checkbox" className="checkbox setCookie" />
                            <label htmlFor="notice__disable-popup">Deze melding niet meer tonen.</label>
                        </div>
                    <div className="clearfix notice__buttons">
                        <button className="notice__cancel" onClick={() => setShowPopup('')}>Terug</button>
                        <button className="notice__continue" onClick={() => {
                            (window as any).top.location.href = showPopup;
                            setShowPopup('');
                        }}>Doorgaan</button>
                    </div>
                </div>
            </article>
        )}

        {/* {isLoading && <Loader />} */}
        <div id="frame-base" className={styles.base}>
            <div className={styles.container}>
                <div className={styles.col2}>
                    {!activePersona ? (<>
                        <div className="js">
                            <div className="content">
                                <h1>{welcome}, waar kunnen we u mee helpen?</h1>
                                <p>Om u beter van dienst te zijn willen wij graag weten wie u bent.</p>
                                <p>Ik ben:</p>
                            </div>
                        </div>

                        <div className={styles.personas}>
                            {personas?.map((persona) => (
                                <div
                                    key={`persona-${persona.id}`}
                                    className={styles.persona}
                                    onClick={() => handlePersona(persona)}
                                >
                                    <div>{persona.name}</div>
                                </div>
                            ))}
                        </div>
                    </>) : (<>
                        <div className="js">
                            <div className="content">
                                <h1>{welcome}, waar kunnen we u mee helpen?</h1>
                                <p>Ik ben een {activePersona.name} (<a href="/" onClick={(e) => { setActivePersona(undefined); e.preventDefault(); }}>wijzig</a>) en heb de volgende vraag:</p>

                                <div className={styles.searchBar}>
                                    {isSearchLoading && <div className={styles.searchLoader}>
                                        <FontAwesomeIcon icon={faCircleNotch} spin />
                                    </div>}
                                    <div className={styles.searchInput}>
                                        <Form.Input
                                            label="Stel uw vraag"
                                            onBlur={() => setTimeout(() => setSearchResults([]), 350)}
                                            onChange={({ value }: any) => handleSearch(value)}
                                            value={searchQuery}
                                        />
                                        <button
                                            onClick={search}
                                        >Zoeken</button>
                                    </div>
                                    {searchResults && searchResults.length > 0 && (
                                        <div className={styles.searchResults}>
                                            {searchResults.map((question) => (
                                                <div
                                                    key={`qr-${question.id}`}
                                                    onClick={() => {
                                                        (window as any).dataLayer.push({
                                                            event: 'faqModuleSearch',
                                                            events: {
                                                                category: 'FAQ module',
                                                                action: 'Search',
                                                                label: question.name,
                                                                search: searchQuery,
                                                            }
                                                        });

                                                        setActiveQuestion(question);
                                                    }}
                                                >
                                                    {question.name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div> 

                                {activeQuestion ? (<>
                                    <h2>{activeQuestion.name}</h2>
                                    {activeQuestion.content.map((block: BlockData, index) => (
                                        <div key={`qb-${activeQuestion.id}-${index}`}>
                                            {parsers[block.block](block)}
                                        </div>
                                    ))}
                                    <h2>Hebben wij uw vraag zo voldoende beantwoord?</h2>
                                    {feedbackSent ? (
                                        <div className={styles.alerts}>
                                            <div className={[styles.alert, styles.alertSuccess, 'text-highlite'].join(' ')}>
                                                <p>Bedankt voor uw feedback!</p>
                                            </div>
                                        </div>
                                    ) : (<>
                                        <div className={styles.thumbs}>
                                            <button type="button" onClick={() => {
                                                (window as any).dataLayer.push({
                                                    event: 'faqModuleSearch',
                                                    events: {
                                                        category: 'FAQ module',
                                                        action: 'Evaluation',
                                                        label: 'Thumbs up',
                                                    }
                                                });

                                                setFeedback(feedback === 1 ? 0 : 1);
                                            }}>
                                                {feedback === 1 ? <>
                                                    <FontAwesomeIcon icon={faThumbsUpSolid} />
                                                    <FontAwesomeIcon icon={faThumbsUpSolid} />
                                                </> : <>
                                                    <FontAwesomeIcon icon={faThumbsUp} />
                                                    <FontAwesomeIcon icon={faThumbsUpSolid} />
                                                </>}
                                            </button>
                                            <button type="button" onClick={() => {
                                                (window as any).dataLayer.push({
                                                    event: 'faqModuleSearch',
                                                    events: {
                                                        category: 'FAQ module',
                                                        action: 'Evaluation',
                                                        label: 'Thumbs down',
                                                    }
                                                });

                                                setFeedback(feedback === 2 ? 0 : 2);
                                            }}>
                                                {feedback === 2 ? <>
                                                    <FontAwesomeIcon icon={faThumbsDownSolid} />
                                                    <FontAwesomeIcon icon={faThumbsDownSolid} />
                                                </> : <>
                                                    <FontAwesomeIcon icon={faThumbsDown} />
                                                    <FontAwesomeIcon icon={faThumbsDownSolid} />
                                                </>}
                                            </button>
                                        </div>
                                        {feedback === 2 && (<>
                                            <Textarea
                                                placeholder="Heeft u verbeter punten?"
                                                rows={4}
                                                style={{ marginTop: '1rem', marginBottom: '.5rem' }}
                                                onChange={({ value }: any) => setFeedbackRes(value)}
                                                value={feedbackRes}
                                            />
                                            <form className="form" style={{ margin: 0 }}>
                                                <button type="button" onClick={() => sendFeedback()}>Versturen</button>
                                            </form>
                                        </>)}
                                    </>)}
                                </>) : (listResults ? (<>
                                    <div className="listResults">
                                        {listResults.map((res) => (
                                            <article className="teaser img-float clearfix">
                                                <a href="/" onClick={(e) => { setActiveQuestion(res); e.preventDefault(); }}>
                                                    <h3>{res.name}</h3>
                                                </a>
                                                <a href="/" onClick={(e) => { setActiveQuestion(res); e.preventDefault(); }} className="internal icon-internal">Meer</a> 
                                            </article>
                                        ))}
                                    </div>
                                    <div style={{ margin: '32px 0' }}>
                                        <h2>Helaas, wij hebben niet direct een passend antwoord op uw vraag.</h2>
                                        <p>Door op de onderstaande knop(pen) te klikken kunt u de vraag direct aan één van onze medewerkers stellen.</p>
                                        <div className={styles.otherOptions} style={{ display: 'flex' }}>
                                            <div className={styles.option} style={{ cursor: 'pointer' }} onClick={() => {
                                                (window as any).$zopim.livechat.window.openPopout();
                                                (window as any).$zopim.livechat.say(`Ik heb een vraag: ${searchQuery}`);
                                            }}>
                                                <div className={styles.optionIcon}>
                                                    <FontAwesomeIcon icon={faComments} />
                                                </div>
                                                <div className={styles.optionInfo}>
                                                    <b>Chat met ons <span className={`${styles.badge} ${chatStatus === 'online' ? styles.badgeOnline: styles.badgeOffline}`} /></b>
                                                    We reageren direct op werkdagen (8:30 - 17:00 uur)
                                                </div>
                                            </div>
                                            <div className={styles.option}>
                                                <div className={styles.optionIcon}>
                                                    <FontAwesomeIcon icon={faEnvelopeOpen} />
                                                </div>
                                                <div className={styles.optionInfo}>
                                                    <a href={`mailto:woerden.receptie@roche.com?subject=Ik heb een vraag&body=${encodeURIComponent(searchQuery)}`}><b>Mailen</b></a>
                                                    We reageren binnen 1 werkdag
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            Wij zijn ook telefonisch bereikbaar op werkdagen tussen 9u en 17u via <a href="tel:0348438000"><b>0348 - 438000</b></a>
                                        </p>
                                    </div>
                                </>) : null)}
                            </div>
                        </div>
                    </>)}
                </div>

                <aside className={styles.col1}>
                    {alerts && alerts.length > 0 && (
                        <div className={styles.alerts}>
                            <div className="js">
                                <div className="content">
                                    {alerts.map((alert, index) => (
                                        <div
                                            key={`alert-${index}`}
                                            className={[styles.alert, 'text-highlite'].join(' ')}
                                        >
                                            <p style={{ marginBottom: alert.content.length > 0 ? -30 : 0 }}><b>{alert.name}</b></p>
                                            {alert.content.map((block: BlockData, index) => (
                                                <div key={`ab-${alert.id}-${index}`}>
                                                    {parsers[block.block](block)}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <h2>Neem contact met ons op</h2>
                    <div className={styles.otherOptions}>
                        <div className={styles.option}>
                            <div className={styles.optionIcon}>
                                <FontAwesomeIcon icon={faPhone} />
                            </div>
                            <div className={styles.optionInfo}>
                                <a href="tel:0348438000"><b>Bellen 0348 - 438000</b></a>
                                Meteen contact op werkdagen (9:00 - 17:00 uur)
                            </div>
                        </div>
                        <div className={styles.option}>
                            <div className={styles.optionIcon}>
                                <FontAwesomeIcon icon={faEnvelopeOpen} />
                            </div>
                            <div className={styles.optionInfo}>
                                <a href="mailto:woerden.receptie@roche.com"><b>Mailen</b></a>
                                We reageren binnen 1 werkdag
                            </div>
                        </div>
                        <div className={styles.option} style={{ cursor: 'pointer' }} onClick={() => {
                            (window as any).$zopim.livechat.window.openPopout();
                        }}>
                            <div className={styles.optionIcon}>
                                <FontAwesomeIcon icon={faComments} />
                            </div>
                            <div className={styles.optionInfo}>
                                <b>Chat met ons <span className={`${styles.badge} ${chatStatus === 'online' ? styles.badgeOnline: styles.badgeOffline}`} /></b>
                                We reageren direct op werkdagen (8:30 - 17:00 uur)
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </>);
}

export default WebsiteView;
